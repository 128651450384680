.service {
	padding-top: 90px;
	padding-bottom: 60px;
	background: #f4f4f4;
	margin-top: 90px;
}

.service-item {
    position: relative;
	margin-top: 5px;
	margin-bottom: 5px;
}

.service .titlepage span {
	color: # 3d3c3c
}

.service .service-box figure {
	margin: 0px;
}

.service .service-box figure img {
	width: 100%;
	border-radius: 20px;
}

.green {
	background-color: #6fb936;
}

.service .service-box .hoverle {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 15px;
	transition: all 0.5s;
    border-radius: 20px;
}

.service .service-box .hoverle a {
	padding: 5px 30px;
	color: #fff;
   font-size: 24px;
   text-shadow: 0 0 1px #000, 0 0 2px #636464;
   font-weight: bolder;
}

.service .titlepage {
	text-align: center;
	padding-bottom: 50px;
}

.service .titlepage h2 {
	padding: 0px 0px 6px 0px;
	font-size: 40px;
	font-weight: bold;
	color: #fc4311;
	line-height: 46px;
	border-bottom: #fc4311 solid 1px;
	width: 210px;
	margin: 0 auto;
	margin-bottom: 0px;
	margin-bottom: 20px;
}

.service .service-box:hover .hoverle {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	display: flex;
	justify-content: center;
	background: #fc431199;
	align-items: center;
	margin: 0 15px;
	border-radius: 20px;
	transition: all 0.5s;
}

.service .service-box:hover .hoverle a {
	border: #fff solid 1px;
	background: transparent;
	padding: 5px 30px;
	color: #fff;
	transition: all 0.5s;
}
