#header {
	background-color: #fc4311 !important;
	padding: 40px 0px;
	padding-bottom: 35px;
}

.navbar-brand {
	font-size: 40px !important;
	font-weight: bold;
	text-transform: uppercase;
	color: #fff;
	line-height: 40px;
}

.main-menu {
	text-align: center;
}

.main-menu ul {
	margin: 0;
	list-style-type: none;
}

.main-menu ul>li {
	display: inline-block;
	position: relative;
}

.main-menu ul>li a {
	padding: 6px 25px 0 25px;	
	line-height: 20px;
	font-size: 17px;
	display: block;
	font-weight: 500;
	color: #fff;
}

.main-menu ul li:last-child a {
	padding-right: 0;
}

.sub-down li {
	background: #ffffff;
}

.main-menu ul>li .sub-down li a {
	color: #114c7d;
	font-size: 15px;
	text-transform: capitalize;
	font-weight: 300;
	padding: 12px 5px;
	position: relative;
	border-bottom: solid #eee 1px;
}

.main-menu ul>li .sub-down li a::before {}

.main-menu ul>li .sub-down li a:hover {
	color: #111111;
}

.main-menu ul>li .sub-down li a:hover::before {}

.main-menu ul li:first-child {
	margin-left: 0;
}

.main-menu ul>li>ul {
	opacity: 0;
	position: absolute;
	text-align: left;
	top: 100%;
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 0 0 0;
	transform-origin: 0 0 0;
	-webkit-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	visibility: hidden;
	width: 240px;
	z-index: 999;
	background: #fff;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.main-menu>ul>li:hover>ul {
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
	visibility: visible;
	opacity: 1;
}

.main-menu ul>li>ul>li {
	margin: 0px;
	position: relative;
	display: block;
}

.main-menu ul>li>ul>li:hover>ul {
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
	visibility: visible;
	opacity: 1;
	left: 100%;
	top: 10px;
}

.main-menu ul>li>ul>li>a {
	background: none !important;
}

.mean-container .mean-nav {
	margin-top: 0px;
	position: absolute;
	top: 100%;
}

.main-menu ul>li {
	position: inherit;
	display: inline-block;
	vertical-align: middle;
}

.main-menu ul>li:nth-child (6) {
	padding-right: 0px;
}
