.footer {
    background: #fc4311;
    padding-top: 55px;
    padding-bottom: 55px;
}

ul.contant_icon {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
}

ul.contant_icon li {
    float: left;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 8px;
}

ul.contant_icon li img {
	width: 32px;
	height: 32px;
}

.copyright p {
	color: #000;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	font-weight: 400;
}

.copyright {
	background-color: #fff;
	padding: 20px 0px;
	margin-top: 55px;
}
