/** slider arrow **/



#myCarousel .carousel-control-prev,
#myCarousel .carousel-control-next {
	width: 62px;
	height: 60px;
	background: #fffdfd;
	opacity: 1;
	font-size: 30px;
	color: #000;
}

#myCarousel .carousel-control-prev:hover,
#myCarousel .carousel-control-next:hover,
#myCarousel .carousel-control-prev:focus,
#myCarousel .carousel-control-next:focus {
	background: #fc4311;
	color: #fff;
}

#myCarousel a.carousel-control-prev {
	position: absolute;
	left: 82px;
	bottom: 10px;
	top: inherit;
}

#myCarousel a.carousel-control-next {
	position: absolute;
	left: 10px;
	top: inherit;
	bottom: 10px;
}

#myCarousel a.carousel-control-next {
	position: absolute;
	top: 40% !important;
	left: inherit !important;
	right: -22px !important;
	width: 70px;
	height: 70px;
	border-radius: 35px;
}

#myCarousel a.carousel-control-prev {
	position: absolute;
	top: 40% !important;
	left: -22px !important;
	width: 70px;
	height: 70px;
	border-radius: 35px;
}

#myCarousel p {
	text-shadow: 0 0 1px #000, 0 0 2px #636464;
	color: #fff;
}

#myCarousel h3 {
	text-shadow: 0 0 1px #000, 0 0 2px #636464;
	color: #fff;
}