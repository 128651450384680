.service-box {
	width: 100%;
	border-radius: 20px;
}

.product-box {
    margin-bottom: 30px;
	text-align: left;
}

.product-box .figure {
		margin: 0px;
}

.product-box h3 {
    font-size: 20px;
    line-height: 30px;
}

.product-box p {
    font-size: 16px;
    line-height: 30px;
}

.product-box img {
	width: 100%;
	/* height: 200px; */
	object-fit: cover;
	border-radius: 20px;
}

.picture-box img {
	width: 100%;
	/* height: 200px; */
	object-fit: cover;
	border-radius: 20px;
    padding: 20px;
    background-color: whitesmoke;
}
.service .titlepage h2 {
	width: 100%;
}
/*
# Features
--------------------------------------------------------------*/
.features .row+.row {
  margin-top: 10px;
}

.features h3 {
  font-weight: 400;
  font-size: 24px;
}

.features ul {
  list-style: none;
  padding: 0;
}

.features ul li {
  padding-bottom: 10px;
}

.features ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #68A4C4;
}

.features p:last-child {
  margin-bottom: 0;
}

.features {
	text-align: left;
}

section {
	padding: 60px 0;
  }


  
  .section-bg {
	background-color: #f3f8fa;
  }
  
  .section-title {
	text-align: center;
	padding-bottom: 30px;
  }
  
  .section-title h2 {
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 20px;
	padding-bottom: 20px;
	position: relative;
  }
  
  .section-title h2::before {
	content: "";
	position: absolute;
	display: block;
	width: 120px;
	height: 1px;
	background: #ddd;
	bottom: 1px;
	left: calc(50% - 60px);
  }
  
  .section-title h2::after {
	content: "";
	position: absolute;
	display: block;
	width: 40px;
	height: 3px;
	background: #68A4C4;
	bottom: 0;
	left: calc(50% - 20px);
  }
  
  .section-title p {
	margin-bottom: 0;
  }

  body {
	font-family: "Open Sans", sans-serif;
	color: #444;
  }
  
  a {
	color: #4fa6d5;
	text-decoration: none;
  }
  
  a:hover {
	color: #45beff;
	text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .font-primary {
	font-family: "Roboto", sans-serif;
  }
  